import React from 'react';
import st from './project-carousel-item.module.scss';
import {TProject} from "../../../types/project";

interface IProjectCarouselItemProps {
    project: TProject;
    selectedBenefitIndex: number;
}

const ProjectCarouselItem = ({project, selectedBenefitIndex}: IProjectCarouselItemProps) => {
    return (
        <div className={st.main}>
            <img className={st.img} src={project.benefits[selectedBenefitIndex].image} alt={project.name}/>
            <div className={st.text__container}>
                <div className={st.project_title}>{project.name}</div>
                <div className={st.description__container}>
                    <div className={st.description_title}>
                        {project.benefits[selectedBenefitIndex].title}
                    </div>
                    <div className={st.description_body}>
                        {project.benefits[selectedBenefitIndex].description}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCarouselItem;
