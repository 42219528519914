import roomsIcon from '../../icons/characteristic-icons/rooms';
import liveAreaIcon from '../../icons/characteristic-icons/live-area';
import floorIcon from '../../icons/characteristic-icons/floor';
import finishingIcon from '../../icons/characteristic-icons/finishing';
import kitchenIcon from '../../icons/characteristic-icons/kitchen';
import furnitureIcon from '../../icons/characteristic-icons/furniture';
import buildingBodyIcon from '../../icons/characteristic-icons/building-body';
import apartmentIcon from "../../icons/characteristic-icons/apartment";
import peculiaritiesIcon from "../../icons/characteristic-icons/peculiarities";
import apartment from "../../icons/characteristic-icons/apartment";
import {TApartment} from "../../types/project";
import {SQUARE_METER_STRING} from "../strings";
import {c} from "../classnames.utils";
import { BENEFITS_TYPES, LAYOUT_TYPES, SECOND_OPTION_NAME_PART } from '../../components/apartment-card/apartment-card';
export interface ICharacteristicItem {
    id: number;
    name: string;
    value: string;
    icon: JSX.Element;
}

export const characteristicList: ICharacteristicItem[] = [
    {
        id: 1,
        name: 'Комнат',
        value: '',
        icon: roomsIcon()
    },
    {
        id: 2,
        name: 'Жилая площадь',
        value: '',
        icon: liveAreaIcon()
    },
    {
        id: 3,
        name: 'Этаж',
        value: '',
        icon: floorIcon()
    },
    {
        id: 4,
        name: 'Отделка',
        value: '',
        icon: finishingIcon()
    },
    {
        id: 5,
        name: 'Кухня',
        value: '',
        icon: kitchenIcon()
    },
    {
        id: 6,
        name: 'Мебель',
        value: '',
        icon: furnitureIcon()
    },
    {
        id: 7,
        name: 'Корпус',
        value: '',
        icon: buildingBodyIcon()
    },
    {
        id: 8,
        name: 'Квартира',
        value: '',
        icon: apartmentIcon()
    },
    {
        id: 9,
        name: 'Особенности',
        value: '',
        icon: peculiaritiesIcon()
    },
]

const getFloorCharacteristic = (characteristic: ICharacteristicItem, apartment: TApartment): ICharacteristicItem | null => {
    // let value: string | null = apartment.floor > 0 ? String(apartment.floor) : null;
    // if (value && apartment.allFloors > 0) {
    //     value += ` из ${apartment.allFloors}`;
    //     return {...characteristic, value: value}
    // }
    // return null;
    return {...characteristic, value: apartment.floor + ' из' + (apartment.floorsCount && apartment.floorsCount > 0 ? ` ${apartment.floorsCount}` : '')};
}



const checkAndPushCharacteristic = (characteristic: ICharacteristicItem, apartment: TApartment): ICharacteristicItem | null => {
    // console.log(apartment.rooms)
    switch (characteristic.id) {
        case 1:
            // console.log(apartment.layouttype)
            return {...characteristic, value: `${LAYOUT_TYPES[apartment.layouttype]?.small ? LAYOUT_TYPES[apartment.layouttype].small : apartment.rooms}`};
        case 2:
            return apartment.square > 0 ? {
                ...characteristic,
                value: `${String(apartment.square)} ${SQUARE_METER_STRING}`
            } : null;
        case 3:
            return getFloorCharacteristic(characteristic, apartment);
        case 4:
            if(apartment.typicalFinishingType && BENEFITS_TYPES.whitebox.includes(apartment.typicalFinishingType)) {
                return {...characteristic, value: 'White box'}
            } else if (
                apartment.typicalFinishingType &&
                (BENEFITS_TYPES.withfinishing.includes(apartment.typicalFinishingType) ||
                BENEFITS_TYPES.withfurniture.includes(apartment.typicalFinishingType) ||
                BENEFITS_TYPES.withkitchen.includes(apartment.typicalFinishingType))
            ) {
                return {...characteristic, value: 'Есть'}
            } else {
                return {...characteristic, value: 'Нет'}
            }
            // return apartment.typicalFinishingType.trim() !== '' ? {...characteristic, value: apartment.finishing} : null;
        case 5:
            if(apartment.typicalFinishingType && BENEFITS_TYPES.withkitchen.includes(apartment.typicalFinishingType)) {
                return {...characteristic, value: 'Есть'}
            } else {
                return {...characteristic, value: 'Нет'}
            }
        case 6:
            if(apartment.typicalFinishingType && BENEFITS_TYPES.withfurniture.includes(apartment.typicalFinishingType)) {
                return {...characteristic, value: 'Есть'}
            } else {
                return {...characteristic, value: 'Нет'}
            }
        case 7:
            return String(apartment.section).trim() !== '' ? {...characteristic, value: `${apartment.buildingNumber} • Секция ${apartment.section}`} : null;
        case 8:
            return apartment.number.trim() !== '' ? {...characteristic, value: `№${apartment.number}`} : null;
        case 9:
            return apartment.peculiarities && apartment.peculiarities.trim() !== '' ? {...characteristic, value: apartment.peculiarities} : null;
    }
    return null;
}

export const getCharacteristicListByApartment = (apartment: TApartment): ICharacteristicItem[] => {
    const result: ICharacteristicItem[] = [];
    characteristicList.forEach(i => {
        try {
            const currentCharacteristic = checkAndPushCharacteristic(i, apartment);
            if (currentCharacteristic) {
                result.push(currentCharacteristic);
            }
        } catch (e) {
            console.error(e);
        }
    })
    return result;
}
