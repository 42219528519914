import React from 'react';
import st from './text-banner.module.scss';

interface ITextBannerProps {
    title: string;
    body: string;
}

const TextBanner = ({title, body}:ITextBannerProps) => {
    return (
        <div className={st.main}>
            <span className={st.title}>{title}</span>
            <span className={st.body}>{body}</span>
        </div>
    );
};

export default TextBanner;
