import React, {useMemo, useState} from 'react';
import st from './drop-down-components.module.scss'
import {c} from "../../../utils/classnames.utils";
import DropDownHandlerArrow from "../../../icons/drop-down-handler-arrow";
import SelectedItemMarker from "../../../icons/selected-item-marker";
import {useSwipeable} from "react-swipeable";

// constants
const STUDIO_OPTION_NAME = 'Студия';
const SECOND_OPTION_NAME_PART = '-комнатная';
export const OPTIONS_LIST_ITEM_HEIGHT = 48;

interface IRoomCountSelectorProps {
    maxRoomCount: number;
    selectedOptions: string[];
    setSelectedOptions: (options: string[]) => void;
}

const RoomCountSelector = ({maxRoomCount, selectedOptions, setSelectedOptions}: IRoomCountSelectorProps) => {
    // drop down list with room count options is open or not
    const [optionListIsView, setOptionListIsView] = useState(false);

    // swipe handlers for carousel
    const handlers = useSwipeable({
        onSwipedUp: () => setOptionListIsView(false),
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    // room count options includes 'studio' and all options from props 'maxRoomCount'.
    const roomCountOptions = useMemo(() => {
        const result: string[] = [STUDIO_OPTION_NAME];
        for (let i = 1; i <= maxRoomCount; i++) {
            result.push(String(i));
        }
        return result;
    }, [maxRoomCount]);

    // makes selected or not selected room count options when user clicks button or row of drop down list.
    const buttonClick = (content: string) => {
        const lastArrayCopy = [...selectedOptions];
        if (lastArrayCopy.includes(content)) {
            setSelectedOptions(lastArrayCopy.filter(i => i !== content));
        } else {
            setSelectedOptions([...lastArrayCopy, content])
        }
    }

    // returns room count option names full versions for drop down list.
    const getFullRoomCountOptionName = (option: string): string => {
        return option === STUDIO_OPTION_NAME ? option : option + SECOND_OPTION_NAME_PART;
    }

    return (
        <div className={st.main}>
            <div className={st.buttons_area}>
                {
                    roomCountOptions.map(bc =>
                        <div key={bc}
                             className={c(st.button, st.resize_able, {[st.selected]: selectedOptions.includes(bc)})}
                             onClick={() => buttonClick(bc)}>{bc}</div>
                    )
                }
            </div>
            <div className={c(st.handler_area, {[st.open]: optionListIsView})}
                 onClick={() => setOptionListIsView(prevState => !prevState)}>
                <DropDownHandlerArrow/>
            </div>
            <div className={c(st.option_list__container, {[st.open]: optionListIsView})}
                 {...handlers}
                 style={{height: optionListIsView ? `${roomCountOptions.length * OPTIONS_LIST_ITEM_HEIGHT}px` : 0}}>
                {
                    roomCountOptions.map(bc =>
                        <div key={bc}
                             className={c(st.option_list_item)}
                             onClick={() => buttonClick(bc)}>
                            {getFullRoomCountOptionName(bc)}
                            <div
                                className={c(st.option_list_selected_marker__container, {[st.visible]: selectedOptions.includes(bc)})}>
                                <SelectedItemMarker/>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default RoomCountSelector;
