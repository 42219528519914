import React, {MutableRefObject, useEffect, useMemo, useRef, useState} from 'react';
import st from './project-carousel.module.scss'
import {TProject} from "../../types/project";
import {SwipeEventData, useSwipeable} from "react-swipeable";
import ProjectCarouselItem from "./project-carousel-item/project-carousel-item";
import ProjectCarouselBenefitButton from "./project-carousel-benefit-button/project-carousel-benefit-button";
import ProjectCarouselProgressLine from "./project-carousel-progress-line/project-carousel-progress-line";

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import { FreeMode, Thumbs, Autoplay } from 'swiper/modules';

const SWIPE_TOLERANCE = 30;

interface IProjectCarouselProps {
    projects: TProject[];
    setProjects: (value: TProject[]) => void;
    selectedProjectIndex: number;
    setSelectedProjectIndex: (val: number) => void;
}

const ProjectCarousel = ({
                             projects,
                             setProjects,
                             selectedProjectIndex,
                             setSelectedProjectIndex
                         }: IProjectCarouselProps) => {
    const carouselLineRef = useRef<HTMLDivElement>(null);
    const [carouselItemWidth, setCarouselItemWidth] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    // const [selectedBenefitIndex, setSelectedBenefitIndex] = useState(0);

    // swipe handlers for carousel
    const handlers = useSwipeable({
        onSwiping: (event) => swipe(event),
        onSwiped: () => swipeEnd(),
        trackMouse: true,
    });

    // changes carousel position when user swipes.
    const swipe = (event: SwipeEventData) => {
        // defines carousel items width if it first swipe or takes what's ready
        const carouselLine = (carouselLineRef as MutableRefObject<HTMLDivElement>).current;
        let itemWidth;
        if (carouselItemWidth > 0) {
            itemWidth = carouselItemWidth;
        } else {
            itemWidth = carouselLine.clientWidth / projects.length;
            setCarouselItemWidth(itemWidth);
        }

        // defines new carousel line position
        const lastCarouselLineLeftPosition = selectedProjectIndex * itemWidth!;
        const newCarouselLineLeftPosition = -lastCarouselLineLeftPosition + event.deltaX;

        // moves carousel of new position is correct
        if (newCarouselLineLeftPosition <= SWIPE_TOLERANCE
            && newCarouselLineLeftPosition >= -(carouselLine.clientWidth - itemWidth) - SWIPE_TOLERANCE
        ) {
            carouselLine.style.transition = 'none';
            carouselLine.style.left = `${newCarouselLineLeftPosition}px`;
        }
    }

    // changes selected project when user finishes swiping
    const swipeEnd = () => {
        const carouselLinePositionAfterSwipe = Number((carouselLineRef as MutableRefObject<HTMLDivElement>).current.style.left.replace(/[^0-9.]/g, ''));
        const newProjectIndex = Math.round(Math.abs(carouselLinePositionAfterSwipe) / carouselItemWidth);
        setSelectedProjectIndex(newProjectIndex);

        const newCarouselLinePosition = newProjectIndex * carouselItemWidth;
        (carouselLineRef as MutableRefObject<HTMLDivElement>).current.style.transition = '.3s';
        (carouselLineRef as MutableRefObject<HTMLDivElement>).current.style.left = `-${newCarouselLinePosition}px`;
    }

    // updates selected benefit index for selected project
    const setSelectedBenefitIndex = (index: number) => {
        const newProjects: TProject[] = [];
        projects.forEach(pr => {
            if (pr.name !== projects[selectedProjectIndex].name) {
                newProjects.push(pr);
            } else {
                newProjects.push({...pr, selectedBenefitIndex: index})
            }
        })
        setProjects(newProjects);
    }

    const progressLine = useRef<HTMLDivElement>(null);

    const onAutoplayTimeLeft = (s: any, time: number, progress: number) => {
        if(progressLine.current === null) return;
        progressLine.current.style.setProperty('--progress', `${1 - progress}`);
    }

    return (
        <div className={st.main}>
            <Swiper
                loop={true}
                slidesPerView={1}
                modules={[Autoplay, FreeMode, Thumbs]}
                className={st.carousel__container}
                thumbs={{ swiper: thumbsSwiper }}
                spaceBetween={16}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                }}
                onSlideChange={(swiper: any) => setSelectedBenefitIndex(swiper.realIndex)}
            >
                {
                    projects.map((project, index) =>
                        project.benefits.map((benefit, index) =>
                            <SwiperSlide key={index}>
                                <ProjectCarouselItem
                                    key={index}
                                    project={project}
                                    selectedBenefitIndex={index}/>
                            </SwiperSlide>
                        )
                    )
                }
                <div className={st.progress_line} ref={progressLine}></div>
            </Swiper>
            <Swiper
                slidesPerView={'auto'}
                loop={true}
                freeMode={true}
                watchSlidesProgress={true}
                onSwiper={setThumbsSwiper}
                modules={[Autoplay, FreeMode, Thumbs]}
                spaceBetween={16}
                className={st.benefit_selection__container}
            >
                {
                    projects[selectedProjectIndex].benefits.map((benefit, index) =>
                        <SwiperSlide className={st.benefit_selection__slide} key={index}>
                            <ProjectCarouselBenefitButton
                                key={index}
                                benefit={benefit}
                                benefitList={projects[selectedProjectIndex].benefits}
                                selectedBenefitIndex={projects[selectedProjectIndex].selectedBenefitIndex}
                                setSelectedBenefitIndex={setSelectedBenefitIndex}/>
                        </SwiperSlide>
                    )
                }
            </Swiper>
            {/* <div className={st.carousel__container} {...handlers}>
                <div className={st.carousel_item__line}
                     style={{"width": `${projects.length * 100}%`}}
                     ref={carouselLineRef}>
                    {
                        projects.map((project, index) =>
                            <ProjectCarouselItem
                                key={index}
                                project={project}
                                selectedBenefitIndex={project.selectedBenefitIndex}/>
                        )
                    }
                </div>
                <div className={st.progress_line__container}>
                    <ProjectCarouselProgressLine allProjectCount={projects.length}
                                                 selectedProjectIndex={selectedProjectIndex}/>
                </div>
            </div>
            <div className={st.benefit_selection__container}>
                {
                    projects[selectedProjectIndex].benefits.map((benefit, index) =>
                        <ProjectCarouselBenefitButton
                            key={index}
                            benefit={benefit}
                            benefitList={projects[selectedProjectIndex].benefits}
                            selectedBenefitIndex={projects[selectedProjectIndex].selectedBenefitIndex}
                            setSelectedBenefitIndex={setSelectedBenefitIndex}/>
                    )
                }
            </div> */}
        </div>
    );
};

export default ProjectCarousel;
