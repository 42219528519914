import React from 'react';

const SelectedItemMarker = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.17435 18C7.70054 18 7.2978 17.7902 6.96613 17.3706L2.36424 11.5507C2.23394 11.3926 2.13918 11.2376 2.07996 11.0855C2.02665 10.9335 2 10.7754 2 10.6112C2 10.2463 2.11845 9.94527 2.35536 9.7081C2.59226 9.47092 2.89136 9.35234 3.25264 9.35234C3.6613 9.35234 4.00481 9.53174 4.28318 9.89054L8.13881 14.9076L15.6635 2.66591C15.8175 2.42265 15.9774 2.25238 16.1433 2.15507C16.3091 2.05169 16.5164 2 16.7651 2C17.1264 2 17.4225 2.11555 17.6535 2.34664C17.8845 2.57773 18 2.87267 18 3.23147C18 3.37742 17.9763 3.52338 17.9289 3.66933C17.8815 3.81528 17.8075 3.96731 17.7068 4.12543L9.39145 17.3341C9.10716 17.778 8.70146 18 8.17435 18Z"
                fill="#F52222"/>
        </svg>

    );
};

export default SelectedItemMarker;
