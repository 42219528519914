// @ts-nocheck
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {AppContext} from "../../context/context";
import TabsArea from "../../components/ui/tabs_area/tabs_area";
import CharacteristicList from "../../components/characteristic-list/characteristic-list";
import {getCharacteristicListByApartment} from "../../utils/lists/characteristic-list";
import BenefitsArea from "../../components/ui/benefits_area/benefits_area";
import {EApartmentBenefitStyleType} from "../../types/project";
import st from './apartment-page.module.scss';
import banner from '../../temporally-source/images/app-baner.png';
import ImageGallery from "../../components/image-gallery/image-gallery";
import {getImageGalleyItemsFromProjectBenefits} from "../../types/image-gallery";
import FinishingOptionsViewer from "../../components/finishing-options-viewer/finishing-options-viewer";
import ApartmentCard from "../../components/apartment-card/apartment-card";
import Button from "../../components/ui/button/button";
import {c} from "../../utils/classnames.utils";
import useTelegram from '../../hooks/useTelegram';
import TelegramAPI from '../../api/telegram';
import WalkingPersonIcon from '../../icons/walking-person-icon';
import TextBanner from '../../components/text-banner/text-banner';

const tabOptions: any = [
    // 'План',
    // 'Этаж',
    // 'Генплан'
]

const ApartmentPage = () => {
    const {projects, selectedProjectIndex, selectedApartment, apartmentList, buildingList, setSelectedApartment, phone} = useContext(AppContext);
    const [selectedTabOption, setSelectedTabOption] = useState<string>('');
    const [isSendOrderClicked, setIsSendOrderClicked] = React.useState(false);
    const [isOrderSending, setIsOrderSending] = React.useState(false);
    const [isOrderSendingError, setisOrderSendingError] = React.useState(false);
    const [errorText, setErrorText] = React.useState('Произошла ошибка, попробуйте еще раз');
    const { telegram, getUserInfo } = useTelegram()
    const mainContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (telegram) {
            telegram.BackButton.show();

            telegram.BackButton.onClick(() => {
                setSelectedApartment(null);
                telegram.BackButton.hide();
            });
        }
        return () => {
            telegram.BackButton.hide();
        };
    }, [telegram])

    const buildFinishBenefit = useMemo(() => {
        if(selectedApartment?.masterPlanImage) {
            tabOptions[2] = 'Генплан';
            setSelectedTabOption('Генплан')
        }
        if(selectedApartment?.floorPlanImage || selectedApartment?.floorPlanImageIframe) {
            tabOptions[1] = 'Этаж';
            setSelectedTabOption('Этаж')
        }
        if(selectedApartment?.planImage && selectedApartment?.planImage !== "https://feeds.kortros.ru/uploads/plans/perhushkovo_svg/.svg") {
            tabOptions[0] = 'План';
            setSelectedTabOption('План')
        }
        return [{
            title: `Сдача объекта: ${selectedApartment?.finishPlan.quarter} квартал ${selectedApartment?.finishPlan.year} года`,
            styleType: EApartmentBenefitStyleType.RED,
        }]
    }, [selectedApartment])

    const projectsName: any = {
        'Равновесие': 'ravnovesie'
    }

    useEffect(() => {
        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                event: "select_item",
                selectedApartment: selectedApartment
            });
            console.log("Событие select_item отправлено в dataLayer");
        } else {
            console.warn("dataLayer не определен");
        }
        if(selectedApartment && !!getUserInfo()) TelegramAPI.sendOpenCardApplication(getUserInfo().id, selectedApartment?.id)
        // telegram.requestContact((e: any, b: any) => {
        //     console.log(e, b)
        // })
        if (isSendOrderClicked && !!selectedApartment && !!getUserInfo() && phone !== '') {
            const userId = getUserInfo().id
            const project = projectsName[selectedApartment?.projectName]
            TelegramAPI.sendConsultation(selectedApartment?.id, userId, project, phone)
        }
        else if (isSendOrderClicked && phone === '') {
            setErrorText('Перезапустите приложение и разрешите использовать Ваш номер телефона.')
            setisOrderSendingError(true);
        }
        else if (isSendOrderClicked && (!selectedApartment || !getUserInfo())) {
            setisOrderSendingError(true);
        }
        const timeout = setTimeout(() => {
            setIsSendOrderClicked(false);
            setisOrderSendingError(false);
            setErrorText('Произошла ошибка, попробуйте еще раз');
        }, 4000)
        return () => {
            clearTimeout(timeout)
        };
    }, [isSendOrderClicked]);

    useEffect(() => {
        mainContainer.current?.scrollTo(0, 0)
    }, [selectedApartment])

    return (
        <div className={st.main} ref={mainContainer}>
            <div className={st.main_content}>
            {
                selectedApartment &&
                <>
                    <div className={st.selected_apartment__container}>
                        {/* <button className={st.back_button} onClick={() => setSelectedApartment(null)}>
                            <svg width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.646447 4.35389C0.451184 4.15863 0.451184 3.84205 0.646447 3.64679L3.82843 0.464806C4.02369 0.269544 4.34027 0.269544 4.53553 0.464806C4.7308 0.660068 4.7308 0.976651 4.53553 1.17191L2.20711 3.50034H16C16.2761 3.50034 16.5 3.7242 16.5 4.00034C16.5 4.27648 16.2761 4.50034 16 4.50034H2.20711L4.53553 6.82877C4.7308 7.02403 4.7308 7.34061 4.53553 7.53587C4.34027 7.73114 4.02369 7.73114 3.82843 7.53587L0.646447 4.35389Z" fill="#000"/>
                            </svg>
                        </button> */}
                        <div className={st.main_info__card}>
                            {
                                selectedTabOption === tabOptions[0] &&
                                <img className={st.img} src={selectedApartment.planImage} alt={''}/>
                            }
                            {
                                selectedTabOption === tabOptions[1] && selectedApartment.floorPlanImage &&
                                <img className={st.img} src={selectedApartment.floorPlanImage} alt={''}/>
                            }
                            {
                                selectedTabOption === tabOptions[1] && selectedApartment.floorPlanImageIframe && selectedApartment.floorPlanImage === '' &&
                                <iframe src={selectedApartment.floorPlanImageIframe} title='floorPlan' className={st.img} frameBorder="0"></iframe>
                            }
                            {
                                selectedTabOption === tabOptions[2] && selectedApartment.masterPlanImage &&
                                <img className={st.img} src={selectedApartment.masterPlanImage} alt={''}/>
                            }
                            <TabsArea options={tabOptions} selectedOption={selectedTabOption}
                                      setSelectedOption={setSelectedTabOption}/>
                            <div className={st.address__container}>
                                <span className={st.address__label}>Адрес</span>
                                <span className={st.address_body}>{selectedApartment.buildingBody}</span>
                                <div className={st.metro__info}>
                                   <span>{`${selectedApartment.metroName}`}</span>
                                   <WalkingPersonIcon/>
                                   <span>{` ≈ ${selectedApartment.metroWalkTime}`}</span>
                                </div>
                            </div>
                        </div>
                        <CharacteristicList list={getCharacteristicListByApartment(selectedApartment)}/>
                        <BenefitsArea benefitList={buildFinishBenefit} fullWidth={true}/>
                        <span className="label"
                              style={{marginTop: "14px", marginBottom: "14px"}}>ДОСТУПНО К ПОКУПКЕ</span>
                        <div className={st.banners__container}>
                            <div className="app_banner">
                                <video src='assets/videos/app-banner.mp4' className="app_banner__video" autoPlay={true} loop={true} playsInline muted></video>
                                {/* <img className="app_banner__img" src={banner} alt={'banner'}/> */}
                                <p className="app_banner__title">Новогодние скидки</p>
                                <p className="app_banner__discount">До 16%</p>
                            </div>
                            <TextBanner title="Trade-in" body="Самый простой способ обменять вашу старую квартиру на новую."/>
                            <TextBanner title="Рассрочка" body="50% первый взнос и далее равными долями на 12 месяцев."/>
                        </div>

                        <span className="label" style={{marginTop: "24px"}}>ОТДЕЛКА</span>
                        <FinishingOptionsViewer options={projects[selectedProjectIndex].finishingOption}/>

                        <span className="label" style={{marginTop: "34px"}}>ОСОБЕННОСТИ</span>
                        <ImageGallery
                            items={getImageGalleyItemsFromProjectBenefits(projects[selectedProjectIndex].specials)}/>
                        <span className="label"
                              style={{marginTop: "24px", marginBottom: "20px"}}>ПОХОЖИЕ КВАРТИРЫ</span>
                    </div>
                    <div className={st.similar_apartment__container}>
                        {
                            apartmentList
                                .filter(apt => apt.id !== selectedApartment.id)
                                .slice(0, 5)
                                .map((apartment) =>
                                    <ApartmentCard key={apartment.id} apartment={apartment}/>
                                )
                        }
                    </div>
                    <div className={st.make_order_button__container}>
                        <Button text={'Получить консультацию'}
                                onClick={() => setIsSendOrderClicked(true)}/>
                        <div className={c(st.make_order_message__container, {[st.active]: isSendOrderClicked})}>
                            {
                                isOrderSending ?
                                    <div className="loader"/> :
                                    isOrderSendingError ?
                                    <span>{ errorText } </span> :
                                    <span>Сообщение доставлено, с Вами свяжутся в ближайшее время.</span>
                            }
                        </div>
                    </div>
                </>
            }
            </div>
        </div>
    );
};

export default ApartmentPage;
