import {TApartment} from "../../types/project";

export const mappingRawToApartment = (rawData: any[]):TApartment[] => {
    const result:TApartment[] = []
    rawData.forEach((rawItem) => {
        let peculiarities = []
        if(rawItem.terrace) peculiarities.push('Терраса')
        if(rawItem.patio) peculiarities.push('Патио')
        if(rawItem.is_highceiling) peculiarities.push('Высокий потолок')

        result.push({
            id: rawItem.guid,
            address: '',
            buildingBody: '',
            number: rawItem.number,
            projectName: 'Равновесие',
            planImage: rawItem.plan,
            metroName: '',
            metroWalkTime: '',
            section: rawItem.section_number,
            rooms: rawItem.room_count,
            square: rawItem.square,
            finishing: rawItem.square ? 'Есть' : 'Нет',
            floor: rawItem.floor_number,
            typicalFinishingType: rawItem.typicalFinishingType,
            buildingId: rawItem.building_id,
            peculiarities: peculiarities.join(', '),
            layouttype: rawItem.layouttype,
            finishPlan: {
                quarter: rawItem.readyQuarter,
                year: rawItem.readyYear
            },
            price: {
                actually: rawItem.discount,
                before: rawItem.total_cost
            }
        })
    })
    return result;
}
