import {
    EApartmentBenefitStyleType,
    TApartment,
    TFinishingOption,
    TProject
} from "../types/project";


const finishingVariables: TFinishingOption[] = [
    {
        id: 1,
        title: 'Чистовая отделка',
        items: [
            {
                roomName: 'Ванная',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAl1l9G59PUJDZTVe7zBMJssPWnmHY96uSWw&s'
            },
            {
                roomName: 'Гостинная',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuCr0ltT9LpXzpsIMvipMnE2LFWtit4cKHgA&s'
            },
            {
                roomName: 'Спальня',
                image: 'https://bizweb.dktcdn.net/100/153/764/products/giuong-ngu-boc-da-50t.jpg?v=1691487709403'
            }
        ]
    },
    {
        id: 2,
        title: 'Без отделки',
        items: [
            {
                roomName: 'Ванная',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKr6vXYnyQxrC1qR-6KEU6Y0rPQu1HSjhH6g&s'
            },
            {
                roomName: 'Гостинная',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlC6yG5Lm41azqlP2a6oimDORUJVPWY0uGVg&s'
            },
            {
                roomName: 'Спальня',
                image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5s_kchM1pVkB-nbAFvqWxYJA_lLY-RwezCw&s'
            }
        ]
    },
]

const apartments: TApartment[] = []
//     [
//     {
//         address: 'Жилой дом корпус 1, с.п.Жаворонковское, г.Одинцово, МО ',
//         buildingBody: 'корпус 1',
//         projectName: 'Равновесие',
//         planImage: 'https://feeds.kortros.ru/uploads/kortros_plan/perhushkovo_svg/q3_h0201_s2_f2_n9.svg',
//         metroName: 'Одинцово',
//         metroWalkTime: '15 мин.',
//         rooms: '1',
//         square: 24.9,
//         liveSquare: 36.8,
//         finishing: 'White Box',
//         finishingOptions: finishingVariables,
//         floor: 2,
//         allFloors: 6,
//         kitchen: true,
//         furniture: false,
//         number: '232',
//         finishPlan: {
//             quarter: 2,
//             year: 2025
//         },
//         benefits: [
//             {
//                 title: 'Акция',
//                 styleType: EApartmentBenefitStyleType.RED,
//             },
//             {
//                 title: 'С отделкой',
//                 styleType: EApartmentBenefitStyleType.WHITE,
//             },
//             {
//                 title: 'Кухня',
//                 styleType: EApartmentBenefitStyleType.WHITE,
//             }
//         ],
//         specialOffers: [
//             {
//                 title: 'Trade-in',
//                 body: 'Самый простой способ обменять вашу старую квартиру на новую.'
//             },
//             {
//                 title: 'Рассрочка',
//                 body: '50% первый взнос и далее равными долями на 12 месяцев.'
//             },
//         ],
//         price: {
//             actually: 21686056,
//             before: 23571800
//         }
//     },
//     {
//         address: 'Жилой дом корпус 1, с.п.Жаворонковское, г.Одинцово, МО ',
//         buildingBody: 'корпус 1',
//         projectName: 'Равновесие',
//         planImage: 'https://feeds.kortros.ru/uploads/kortros_plan/perhushkovo_svg/q2_h0005_s7_f6_n6.svg',
//         metroName: 'Одинцово',
//         metroWalkTime: '15 мин.',
//         rooms: 'Студия',
//         square: 28,
//         finishing: 'White Box',
//         finishingOptions: finishingVariables,
//         kitchen: true,
//         liveSquare: 36.8,
//         floor: 4,
//         allFloors: 6,
//         furniture: false,
//         peculiarities: 'Терасса',
//         number: '255',
//         finishPlan: {
//             quarter: 2,
//             year: 2025
//         },
//         benefits: [
//             {
//                 title: 'Акция',
//                 styleType: EApartmentBenefitStyleType.RED,
//             },
//             {
//                 title: 'С отделкой',
//                 styleType: EApartmentBenefitStyleType.WHITE,
//             }
//         ],
//         specialOffers: [
//             {
//                 title: 'Trade-in',
//                 body: 'Самый простой способ обменять вашу старую квартиру на новую.'
//             },
//             {
//                 title: 'Рассрочка',
//                 body: '50% первый взнос и далее равными долями на 12 месяцев.'
//             },
//         ],
//         price: {
//             actually: 5008920,
//             before: 5620350
//         }
//     },
//     {
//         address: 'Жилой дом корпус 1, с.п.Жаворонковское, г.Одинцово, МО ',
//         buildingBody: 'корпус 1',
//         projectName: 'Равновесие',
//         planImage: 'https://feeds.kortros.ru/uploads/kortros_plan/perhushkovo_svg/q2_h0003_s4_f4_n1.svg',
//         metroName: 'Одинцово',
//         metroWalkTime: '15 мин.',
//         rooms: '3',
//         square: 83.5,
//         finishing: 'White Box',
//         finishingOptions: finishingVariables,
//         liveSquare: 36.8,
//         floor: 2,
//         kitchen: true,
//         allFloors: 6,
//         furniture: false,
//         number: '232',
//         finishPlan: {
//             quarter: 2,
//             year: 2025
//         },
//         benefits: [
//             {
//                 title: 'Акция',
//                 styleType: EApartmentBenefitStyleType.RED,
//             },
//             {
//                 title: 'С отделкой',
//                 styleType: EApartmentBenefitStyleType.WHITE,
//             },
//             {
//                 title: 'Кухня',
//                 styleType: EApartmentBenefitStyleType.WHITE,
//             }
//         ],
//         specialOffers: [
//             {
//                 title: 'Trade-in',
//                 body: 'Самый простой способ обменять вашу старую квартиру на новую.'
//             }
//         ],
//         price: {
//             actually: 15977725,
//             before: 17956200
//         }
//     },
//     {
//         address: 'Жилой дом корпус 1, с.п.Жаворонковское, г.Одинцово, МО ',
//         buildingBody: 'корпус 1',
//         projectName: 'Равновесие',
//         planImage: 'https://feeds.kortros.ru/uploads/kortros_plan/perhushkovo_svg/q3_h0101_s3_f6_n6.svg',
//         metroName: 'Одинцово',
//         metroWalkTime: '15 мин.',
//         rooms: '1',
//         square: 75.1,
//         finishing: 'White Box',
//         finishingOptions: finishingVariables,
//         liveSquare: 36.8,
//         floor: 2,
//         kitchen: true,
//         furniture: false,
//         allFloors: 6,
//         number: '22',
//         finishPlan: {
//             quarter: 2,
//             year: 2025
//         },
//         benefits: [
//             {
//                 title: 'Акция',
//                 styleType: EApartmentBenefitStyleType.RED,
//             },
//             {
//                 title: 'Кухня',
//                 styleType: EApartmentBenefitStyleType.WHITE,
//             }
//         ],
//         specialOffers: [],
//         price: {
//             actually: 16424370,
//             before: 18249360
//         }
//     }
// ]

export const temporallyProjects: TProject[] = [
    {
        name: 'Равновесие',
        benefits: [
            {
                title: 'Скидки в ЖК Равновесие до 16%',
                description: 'Малоэтажный зеленый квартал рядом с Одинцово. Европейская архитектура, свои школа и детские сады, безопасные дворы и развитая инфраструктура. Все что нужно для жизни в гармонии – всего в 35 минутах от Москва-Сити',
                image: 'https://api.kortros.ru/files/content/%D0%9D%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%B2%D0%B7%D0%B3%D0%BB%D1%8F%D0%B4%20%D0%BD%D0%B0%20%D0%BF%D1%80%D0%B8%D0%B3%D0%BE%D1%80%D0%BE%D0%B4-1-2.png?ownerId=2&type=BENEFITS'
            },
            {
                title: 'Green Development',
                description: 'Расширенная площадь остекления, экологически чистые материалы, обеспечение энергоэффективности, дворы «без машин» — основные составляющие концепции Green Development, которой мы придерживались при создании проекта.',
                image: 'https://api.kortros.ru/files/content/Green%20Development-2-2.png?ownerId=2&type=BENEFITS'
            },
            {
                title: 'Безбарьерная среда',
                description: 'Большое внимание уделяется всем важным деталям: вход в подъезд на уровне земли, лифты, зоны для хранения детских колясок в подъездах.',
                image: 'https://api.kortros.ru/files/content/%D0%91%D0%B5%D0%B7%D0%B1%D0%B0%D1%80%D1%8C%D0%B5%D1%80%D0%BD%D0%B0%D1%8F%20%D1%81%D1%80%D0%B5%D0%B4%D0%B0-3-2.png?ownerId=2&type=BENEFITS'
            },
            {
                title: 'Развивающие площадки для детей',
                description: 'Во дворах размещены детские площадки для детей возраста 0-7 лет: песочницы, качели, небольшие горки. В центральную зону вынесены площадки для детей всех возрастов с разнообразным игровым оборудованием и пространствами.',
                image: 'https://api.kortros.ru/files/content/perhushkovo_perhushkovo_14_ravnovesie_promenadview03_day_ver11_wp_1705236020524_28052024_1435.jpg?ownerId=2&type=BENEFITS'
            },
            {
                title: 'Комплексные спортивные площадки ',
                description: 'Здесь есть всё для занятий спортом — тренажеры и рама для воркаута, площадки для командных игр, зона для настольного тенниса, площадка для игры в петанк. А ещё вдоль всего бульвара пролегает велодорожка.',
                image: 'https://api.kortros.ru/files/content/perhushkovo_perhushkovo_18_ravnovesie_volleybalview_ver06_1705235795672_28052024_1434.jpeg?ownerId=2&type=BENEFITS'
            }
        ],
        specials: [
            {
                title: 'Детский сад',
                description: 'В составе проекта строительства детский сад на 360 воспитанников.',
                image: 'https://api.kortros.ru/files/content/perhushkovo_detsad_1711109902310.jpeg?ownerId=2&type=FEATURES'
            },
            {
                title: 'Школа',
                description: '',
                image: 'https://api.kortros.ru/files/content/perhushkovo_05_ravnovesie_school_facadeview01_day_ver01_hr_1711109837343.jpg?ownerId=2&type=FEATURES'
            },
            {
                title: 'Квартиры с террасами и патио',
                description: 'На первых этажах расположены лоты с патио, с отдельным входом, а на последних этажах - квартиры с террасами.',
                image: 'https://api.kortros.ru/files/content/%D0%9A%D0%B2%D0%B0%D1%80%D1%82%D0%B8%D1%80%D1%8B%20%D1%81%20%D1%82%D0%B5%D1%80%D1%80%D0%B0%D1%81%D0%B0%D0%BC%D0%B8%20%D0%B8%20%D0%BF%D0%B0%D1%82%D0%B8%D0%BE-4-2.png?ownerId=2&type=FEATURES'
            },
            {
                title: 'Благоустроенные прогулочные маршруты',
                description: 'Центральная часть представляет единое пространство, которое служит зоной как для активных прогулок, так и для неспешного променада жителей.',
                image: 'https://api.kortros.ru/files/content/%D0%91%D0%BB%D0%B0%D0%B3%D0%BE%D1%83%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%BF%D1%80%D0%BE%D0%B3%D1%83%D0%BB%D0%BE%D1%87%D0%BD%D1%8B%D0%B5%20%D0%BC%D0%B0%D1%80%D1%88%D1%80%D1%83%D1%82%D1%8B-2-2.png?ownerId=2&type=FEATURES'
            },
            {
                title: 'Квартиры с отделкой',
                description: 'В жилом квартале Равновесие предусмотрены квартиры с готовой дизайнерской отделкой в двух стилях, а также есть отделка white box.',
                image: 'https://api.kortros.ru/files/content/%D0%9A%D0%B2%D0%B0%D1%80%D1%82%D0%B8%D1%80%D1%8B%20%D1%81%20%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%BA%D0%BE%D0%B9-5-2.png?ownerId=2&type=FEATURES'
            }
        ],
        finishingOption: [
            {
                id: 1,
                title: 'Современный стиль',
                items: [
                    {
                        roomName: 'Кухня-гостиная',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_kuhnya2.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Детская',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_detskaya_sovr.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Кухня-гостиная',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_gostinaya2.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Спальня',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_spaljnya2.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Прихожая',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_prihozhaya2.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Ванная',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_sanuzel2_1.jpg?ownerId=2&type=FINISHING'
                    },
                ]
            },
            {
                id: 2,
                title: 'White box',
                items: [
                    {
                        roomName: 'Внутренние перегородки',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_1_peregorodki_1709132141065.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Покрытие стен',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_2_finishnoe_pokrytie_sten_1709132250663.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Стяжка пола',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_3_styazhki_pol_1709132311169.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Кабельные линии',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_4_razvodka_dlya_osvescheniya_1709132337540.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Розеточная группа',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_6_plita_1709132471823.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Кондиционирование',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_7_konditsionery_1709132541246.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Радиаторы',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_8_radiatory_1709132566420.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Пожарная сигнализация',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_9_pozharnaya_signalizatsiya_1709132610036.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Двери',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_11_vhodnaya_dverj_1709132715196.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Окна',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_14_okna_1709133413420.png?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Подоконник',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_16_podokonniki_1709133470457.png?ownerId=2&type=FINISHING'
                    },
                ]
            },
            {
                id: 3,
                title: 'Скандинавский стиль',
                items: [
                    {
                        roomName: 'Прихожая',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_prihozhaya_rv.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Спальня',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_spaljnya_rv.jpeg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Кухня-гостиная',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_kuhnya_rv.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Кухня-гостиная',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_gostinaya_rv.jpeg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Детская',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_detskaya_hyugge.jpg?ownerId=2&type=FINISHING'
                    },
                    {
                        roomName: 'Ванная',
                        image: 'https://api.kortros.ru/files/content/perhushkovo_sanuzel_rv.jpg?ownerId=2&type=FINISHING'
                    },
                ]
            },
        ],
        selectedBenefitIndex: 0,
        apartments: apartments
    },
    // {
    //     name: 'ILove',
    //     benefits: [
    //         {
    //             title: 'Город',
    //             description: 'Квартал находится на улице Бочкова, в тихом зелёном районе Останкино, в 10 мин от метро «Алексеевская». Рядом ВДНХ, парк-усадьба «Останкино», Ботанический сад, Сокольники».',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333618_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-6.jpg'
    //         },
    //         {
    //             title: 'Комфорт',
    //             description: 'Жилой квартал iLove оснащен современными инженерными системами, отвечающими за комфорт, безопасность и сбережение ресурсов. Окна в квартирах оборудованы энергоэффективным стеклом, которое сохраняет комфортную температуру вне зависимости от сезона. Система «Умный дом» поможет персонализировать ваше жилое пространство и сделать его ещё более безопасным и комфортным.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333584_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-7.jpg'
    //         },
    //         {
    //             title: 'Архитектура',
    //             description: 'Большое внимание уделяется всем важным деталям: вход в подъезд на уровне земли, лифты, зоны для хранения детских колясок в подъездах.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333550_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-8.jpg'
    //         },
    //         {
    //             title: 'Лобби',
    //             description: 'В семейном жилом квартале iLove ваш комфорт будет обеспечен множеством приятных деталей. В просторных лобби вашим гостям или курьерам будет удобно подождать вас. Вместительные келлеры (помещения для хранения вещей) позволят освободить пространство квартиры. Подземный паркинг, в который вы можете спуститься на скоростном лифте непосредственно от квартиры.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/thumbs/1679333545_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-12.jpg'
    //         }
    //     ],
    //     selectedBenefitIndex: 0,
    //     apartments: apartments
    // },
    // {
    //     name: 'Равновесие1',
    //     benefits: [
    //         {
    //             title: 'Новый взгляд на пригород',
    //             description: '«Равновесие» — это место, где не нужно никуда торопиться. Просто уделяйте время себе, ведь здесь всё рядом. Детский сад, кафе и рестораны, аптеки и многое другое в собственной инфраструктуре ЖК.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333571_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-1.jpg'
    //         },
    //         {
    //             title: 'Green Development',
    //             description: 'Расширенная площадь остекления, экологически чистые материалы, обеспечение энергоэффективности, дворы «без машин» — основные составляющие концепции Green Development, которой мы придерживались при создании проекта.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333619_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-2.jpg'
    //         },
    //         {
    //             title: 'Безбарьерная среда',
    //             description: 'Большое внимание уделяется всем важным деталям: вход в подъезд на уровне земли, лифты, зоны для хранения детских колясок в подъездах.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333560_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-3.jpg'
    //         },
    //         {
    //             title: 'Развивающие площадки для детей',
    //             description: 'Во дворах размещены детские площадки для детей возраста 0-7 лет: песочницы, качели, небольшие горки. В центральную зону вынесены площадки для детей всех возрастов с разнообразным игровым оборудованием и пространствами.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333620_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-4.png'
    //         },
    //         {
    //             title: 'Многофункциональные спортивные',
    //             description: 'Здесь есть всё для занятий спортом — тренажеры и рама для воркаута, площадки для командных игр, зона для настольного тенниса, площадка для игры в петанк. А ещё вдоль всего бульвара пролегает велодорожка.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333524_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-5.jpg'
    //         }
    //     ],
    //     selectedBenefitIndex: 0,
    //     apartments: apartments
    // },
    // {
    //     name: 'ILoves',
    //     benefits: [
    //         {
    //             title: 'Город',
    //             description: 'Квартал находится на улице Бочкова, в тихом зелёном районе Останкино, в 10 мин от метро «Алексеевская». Рядом ВДНХ, парк-усадьба «Останкино», Ботанический сад, Сокольники».',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333618_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-6.jpg'
    //         },
    //         {
    //             title: 'Комфорт',
    //             description: 'Жилой квартал iLove оснащен современными инженерными системами, отвечающими за комфорт, безопасность и сбережение ресурсов. Окна в квартирах оборудованы энергоэффективным стеклом, которое сохраняет комфортную температуру вне зависимости от сезона. Система «Умный дом» поможет персонализировать ваше жилое пространство и сделать его ещё более безопасным и комфортным.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333584_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-7.jpg'
    //         },
    //         {
    //             title: 'Архитектура',
    //             description: 'Большое внимание уделяется всем важным деталям: вход в подъезд на уровне земли, лифты, зоны для хранения детских колясок в подъездах.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/1679333550_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-8.jpg'
    //         },
    //         {
    //             title: 'Лобби',
    //             description: 'В семейном жилом квартале iLove ваш комфорт будет обеспечен множеством приятных деталей. В просторных лобби вашим гостям или курьерам будет удобно подождать вас. Вместительные келлеры (помещения для хранения вещей) позволят освободить пространство квартиры. Подземный паркинг, в который вы можете спуститься на скоростном лифте непосредственно от квартиры.',
    //             image: 'https://design.pibig.info/uploads/posts/2023-03/thumbs/1679333545_design-pibig-info-p-krasivie-mnogoetazhnie-doma-dizain-pintere-12.jpg'
    //         }
    //     ],
    //     selectedBenefitIndex: 0,
    //     apartments: apartments
    // }

]
